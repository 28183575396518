export const onTouchField = function (field) {
  if (this[field].length) {
    this.$v[field].$touch();
  }
};

export const removeKeyFromServerValid = function (key) {
  if (key in this.serverValid) {
    const newValid = { ...this.serverValid };
    delete newValid[key];

    this.serverValid = newValid;
  }
};

// https://56f55f5187b94229a4041543eab1f306@sentry.i-assistant.online/19
// 5f81ef5b5115603d894d753320a473a163a5bd5e
